<template>
  <v-tabs v-model="data" center-active grow class="mainTab mt-3">
    <v-tab v-for="(option, index) in options" :key="index">
      <v-badge v-if="count(option) > 0" color="red" :content="count(option)" class="mt-2">{{ option.text }}</v-badge>
      <template v-else>{{ option.text }}</template>
    </v-tab>

    <component v-if="metaTab" :is="metaTab" :list-key="listKey"></component>
  </v-tabs>
</template>

<script>
export default {
  props: {
    listKey: String,
    options: Array,
    value: Number,
    metaTab: {
      type: Function,
      default: null,
    }
  },
  data: () => ({
    data: 0,
  }),
  watch: {
    value() {
      this.data = this.value
    },
    data: {
      immediate: true,
      handler() {
        this.$emit("input", this.data);
        const value = 1
        this.$store.dispatch(`list/${this.listKey}/set`, {
          key: "page",
          value,
        });
      },
    },
  },
  methods: {
    count(option) {
      if (!option.count) return null;
      return option.count;
    },
  },
  computed: {
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
  },
};
</script>

<style lang="sass">
.mainTab
  background: #f6f6f6
  .v-slide-group__prev,.v-slide-group__next,.v-tabs-slider-wrapper
    display: none !important
  .v-tab
    min-width: 105px
    // max-width: 115px !important
  .v-slide-group__wrapper
    background: #F6F6F6

.provider-store-layout
  .mainTab
    .v-tab
      background: #F6F6F6 !important
      color: #122A47 !important
      border-left: 1px solid #ffffff
      border-right: 1px solid #ffffff
      &--active
        background: #122A47 !important
        border: 1px solid #122A47
        color: #ffffff !important

.linerp-layout
  .mainTab
    .v-tab
      background: #F6F6F6 !important
      color: #00281D !important
      border-left: 1px solid #ffffff
      border-right: 1px solid #ffffff
      &--active
        background: #FF5100 !important
        border: 1px solid #ffffff
        color: #ffffff !important

.home-order-tab
  .mainTab
    .v-tab
      max-width: initial !important
</style>