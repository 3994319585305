import { render, staticRenderFns } from "./tabs.vue?vue&type=template&id=3c807698&"
import script from "./tabs.vue?vue&type=script&lang=js&"
export * from "./tabs.vue?vue&type=script&lang=js&"
import style0 from "./tabs.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBadge,VTab,VTabs})
